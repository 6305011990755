import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonRow, isPlatform } from '@ionic/react';
import { close, search } from 'ionicons/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { NormalText, SmallText, Spacer, StrongText, Subtitle } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { forwardTo, getDistanceUnit, goBack, isDefined, isEmptyObject } from '../../lib/utils';
import location from '../../assets/images/map-pin-filled.svg';
import { setScrollTop } from '../../store/actions';
import './index.css';
import OrderList from './orderList';
import TileOrderList from './tileOrderList';
import moment from 'moment';

class OrderPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCategory: 0,
			ikentooMenu: null,
			originalMenu: null,
			menuRefs: null,
			categoriesPositionTop: [],
			selectedMenu: [],
			searchItemsValue: '',
			searchOpened: false,
		};
		this.selectCategoryOnScroll = this.selectCategoryOnScroll.bind(this);
	}

	setIkentooMenu = () => {
		let ikentooMenu = this.props.defaultMenu;
		let originalMenu = this.props.defaultMenu;
		if (!isEmptyObject(this.props.ikentooMenu) && (Basket.getOrderType())) {
			ikentooMenu = this.props.ikentooMenu;
			originalMenu = this.props.ikentooMenu;
		}
		this.setState({ ikentooMenu, originalMenu },
			() => {
				let menuRefs = this.menuRefs(this.state.ikentooMenu);
				this.setState({ menuRefs });
			},
		);
		if (this.state.searchItemsValue !== '') {
			this.filterMenuItems(this.state.searchItemsValue);
		}
	};

	componentDidMount() {
		const { scrollTop } = this.props;
		this.setIkentooMenu();
		this.setState({
			selectedMenuType: getConfig().menuDisplayType,
		});
		if (this.props.location.state) {
			this.setState({ selectedMenu: this.props.location.state.selectedMenu });
		}
		setTimeout(() => {
			const item = document.querySelector('.order-list-items');
			if (item) {
				item.scrollTop = scrollTop;
				if (!isPlatform('ios')) {
					item.scrollBy({ top: 1, behavior: 'smooth' });
				}
			}
		}, 500);
	}

	shouldComponentUpdate(nextProps) {
		const prevMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu) ? nextProps.defaultMenu : nextProps.ikentooMenu || {}).menuName;
		const currentMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu) ? this.props.defaultMenu : this.props.ikentooMenu || {}).menuName;
		if (prevMenuName !== currentMenuName || nextProps.restaurantsUpdated !== this.props.restaurantsUpdated) {
			this.setIkentooMenu();
		}
		return true;
	}

	menuRefs = (menuRefs) => {
		return menuRefs && menuRefs.menuEntryGroups
			? menuRefs.menuEntryGroups.reduce((acc, value, index) => {
				let name = `${value.name}_${index}`;
				acc[name] = React.createRef();
				return acc;
			}, {})
			: {};
	};

	scrollTo = (name) => this.state.menuRefs[name].current.scrollIntoView();
	selectCategoryOnScroll = (e) => {
		const scrollTop = Math.abs(e.target.scrollTop);
		const heading = document.querySelector('.order-header-content-wrapper');
		if (heading && scrollTop > 10) {
			heading.style.display = 'none';
		} else {
			heading.style.display = 'block';
		}
		e.preventDefault();
		const positionTop = scrollTop + document.querySelector('.order-categories').clientHeight;
		const { menuRefs } = this.state;
		const positions = Object.keys(menuRefs).map((key) => {
			return menuRefs[key].current.offsetTop;
		});
		let selectCategory = 0;
		positions.forEach((item, i) => {
			if (item <= positionTop) {
				selectCategory = i;
			}
		});
		if (document) {
			const catDiv = document.querySelector('.order-categories-inner');
			if (catDiv && catDiv.childNodes && catDiv.childNodes[selectCategory]) {
				catDiv.childNodes[selectCategory].scrollIntoView({
					behavior: 'smooth',
					inline: 'start',
				});
			}
		}
		this.props.dispatch(setScrollTop(scrollTop));
		this.setState({ selectedCategory: selectCategory, scrollTop: scrollTop });
	};

	backHandler = () => {
		let menuDisplayType = this.state.selectedMenuType;
		const selectedMenus = this.state.selectedMenu || [];

		if (menuDisplayType !== 1 && selectedMenus.length > 0) {
			const items = this.state.selectedMenu;
			items.pop();
			this.setState({ selectedMenu: items });
		} else {
			goBack();
		}
	};
	hideBack = () => {
		let menuDisplayType = getConfig().menuDisplayType;
		const selectedMenus = this.state.selectedMenu || [];
		if (menuDisplayType !== 1 && selectedMenus.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	selectMenu = (index) => {
		const selectedItem = index;
		const currentCategories = this.state.selectedMenu ? [...this.state.selectedMenu] : [];
		currentCategories.push(selectedItem);
		this.setState({ selectedMenu: currentCategories });
	};

	parseCategories = (data) => {
		const { menuDisplayType, ikentooMenu } = data;
		const selectedMenu = data.selectedMenu || [];

		let categories;
		if (menuDisplayType !== 1) {
			categories = !isEmptyObject(ikentooMenu) ? ikentooMenu : [];
			if (selectedMenu.length > 0) {
				categories = categories.menuEntryGroups[selectedMenu[0]];
				for (let i = 1; i < selectedMenu.length; i++) {
					categories = categories.menuEntry[selectedMenu[i]];
				}
			}
		} else {
			categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : [];
		}
		return categories;
	};
	filterMenu(menu) {
		if (menu && menu.menuEntryGroups) {
			menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
			return menu;
		}
		return menu;
	}
	filterIkentooMenuItems(items) {
		let filteredItems = [];
		items.forEach((item) => {
			if (!item.menuEntry) {
				// break recursion when arrive to the product
				if (Basket.getProductName(item, this.props.profile).toLowerCase().includes(`${this.state.searchItemsValue.toLowerCase()}`)) {
					filteredItems.push(item);
					return [item];
				} else {
					return [];
				}
			} else {
				const len = (item.menuEntry || []).length;
				if (len > 0) {
					const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
					if (newFilteredItems.length > 0) {
						item.menuEntry = newFilteredItems;
						filteredItems.push(item);
					}
				}
			}
		});

		return filteredItems;
	}
	filterMenuItems = (value) => {
		this.setState({ searchItemsValue: value }, () => {
			if (getConfig().menuDisplayType !== 1) {
				if (this.state.selectedMenuType !== 1 && this.state.searchItemsValue !== '') {
					this.setState({ selectedMenuType: 1 }, () => {
						const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
						const filtered = this.filterMenu(menu);
						this.setState({ ikentooMenu: filtered }, () => {
							let menuRefs = this.menuRefs(this.state.ikentooMenu);
							this.setState({ menuRefs });
						});
					});
				} else if (this.state.selectedMenuType === 1 && this.state.searchItemsValue === '') {
					this.setState({ selectedMenuType: getConfig().menuDisplayType }, () => {
						const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
						const filtered = this.filterMenu(menu);
						this.setState({ ikentooMenu: filtered }, () => {
							let menuRefs = this.menuRefs(this.state.ikentooMenu);
							this.setState({ menuRefs });
						});
					});
				} else {
					const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
					const filtered = this.filterMenu(menu);
					this.setState({ ikentooMenu: filtered }, () => {
						let menuRefs = this.menuRefs(this.state.ikentooMenu);
						this.setState({ menuRefs });
					});
				}
			} else {
				const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
				const filtered = this.filterMenu(menu);
				this.setState({ ikentooMenu: filtered }, () => {
					let menuRefs = this.menuRefs(this.state.ikentooMenu);
					this.setState({ menuRefs });
				});
			}
		});
	};

	formatDayName = (name) => {
		if (name.includes('Today')) {
			name = 'Today'.toLowerCase();
		} else if (name.includes('Tomorrow')) {
			name = 'Tomorrow'.toLowerCase();
		} else {
			name = Basket.getOrderDate();
		}
		return name;
	};
	drawContentHeader = (__, deliveryOption, orderType, deliveryAddress) => {
		let dateName = this.formatDayName(
			moment(Basket.getOrderDate(), 'dddd Do MMMM').calendar(null, {
				sameDay: '[Today]',
				nextDay: '[Tomorrow]',
			}),
		);
		const selectedRestaurant = Basket.getRestaurant();
		if(!isEmptyObject(deliveryOption)){
			if (deliveryOption.id === 'pick-up-point') {
				return (
					<div className="box-content order-header-content">
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								stroke="var(--okx-box-wrapper-color)"
								className="icon icon-tabler icon-tabler-map"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
								<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
								<line x1="9" y1="4" x2="9" y2="17"></line>
								<line x1="15" y1="7" x2="15" y2="20"></line>
							</svg>
							<StrongText className="bold primary-color">{Basket.getRestauranName()}</StrongText>
						</div>
					</div>
				);
			} else if (deliveryOption.id === 'charter-delivery' && !isEmptyObject(deliveryAddress)) {
				return (
					<div className="box-content order-header-content">
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								stroke="var(--okx-box-wrapper-color)"
								className="icon icon-tabler icon-tabler-map"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
								<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
								<line x1="9" y1="4" x2="9" y2="17"></line>
								<line x1="15" y1="7" x2="15" y2="20"></line>
							</svg>
							<StrongText className="bold primary-color">{Basket.getRestauranName()}</StrongText>
						</div>
						<div>
							<NormalText>{__('Delivery to')}</NormalText>
						</div>
						<NormalText>
							{deliveryAddress.addressLine1}, {deliveryAddress.place} {deliveryAddress.postalCode} {dateName} {__('at')} {Basket.getOrderTime()}
						</NormalText>
					</div>
				);
			} else if (deliveryOption.id === 'table') {
				return (
					<div className="box-content order-header-content">
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								stroke="var(--okx-box-wrapper-color)"
								className="icon icon-tabler icon-tabler-map"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
								<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
								<line x1="9" y1="4" x2="9" y2="17"></line>
								<line x1="15" y1="7" x2="15" y2="20"></line>
							</svg>{' '}
							<StrongText className="bold primary-color">{Basket.getRestauranName()}</StrongText>
						</div>
						<div>
							<NormalText>{__('Table number')}</NormalText>
							<NormalText >{Basket.getTableNumber()}</NormalText>
						</div>
					</div>
				);
			} else if (deliveryOption.id === 'scheduled-delivery') {
				return (
					<div className="box-content order-header-content">
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								stroke="var(--okx-box-wrapper-color)"
								className="icon icon-tabler icon-tabler-map"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
								<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
								<line x1="9" y1="4" x2="9" y2="17"></line>
								<line x1="15" y1="7" x2="15" y2="20"></line>
							</svg>
							<StrongText className="bold primary-color">{Basket.getRestauranName()}</StrongText>
						</div>
						<div>
							<NormalText>{__('Delivery to')}</NormalText>
						</div>
						<NormalText className='block'>
							{deliveryAddress.addressLine1}, {deliveryAddress.place} {deliveryAddress.postalCode} {dateName}
	
	
						</NormalText>
						<NormalText className='block'> {dateName} {__('at')} {Basket.getOrderTime()}</NormalText>
					</div>
				);
			} else if (deliveryOption.id === 'scheduled-collection') {
				return (
					<div className="box-content order-header-content">
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								stroke="var(--okx-box-wrapper-color)"
								className="icon icon-tabler icon-tabler-map"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
								<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
								<line x1="9" y1="4" x2="9" y2="17"></line>
								<line x1="15" y1="7" x2="15" y2="20"></line>
							</svg>{' '}
							<StrongText className="bold primary-color">{Basket.getRestauranName()}</StrongText>
						</div>
						<div>
							<NormalText>{__('Collection time')}</NormalText>
						</div>
						<NormalText >
							<StrongText className="bold">
								{Basket.additional_data?.named_time_slots_data ? (
									<>
										{dateName} {'at'} {Basket.additional_data?.named_time_slots_data}
									</>
								) : (
									<>
										{dateName} {'at'} {Basket.getOrderTime()}
									</>
								)}
							</StrongText>
						</NormalText>
					</div>
				);
			} else if (deliveryOption.id === 'pick-up-at-counter') {
				return (
					<div className="box-content order-header-content">
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								stroke="var(--okx-box-wrapper-color)"
								className="icon icon-tabler icon-tabler-map"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
								<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
								<line x1="9" y1="4" x2="9" y2="17"></line>
								<line x1="15" y1="7" x2="15" y2="20"></line>
							</svg>{' '}
							<StrongText className="bold primary-color">{Basket.getRestauranName()}</StrongText>
						</div>
					</div>
				);
			} else if (deliveryOption.id === 'browse-menu' && selectedRestaurant) {
				return (
					<div className="box-content order-header-content">
						<div>
							<Subtitle className='bold' >{selectedRestaurant.name} &nbsp;</Subtitle>
							{selectedRestaurant.browse_menu_json_time_selector > 1 ? (
								<div onClick={() => forwardTo(`/browse-menu?bl_id=${selectedRestaurant.business_location_id}`)} >
									<Subtitle className='bold link underlined'>
										{`${__('View other menus')}`}
									</Subtitle>
								</div>
							) : null
							}
						</div>
						<div>
							<SmallText className="block">{__(selectedRestaurant.address)}</SmallText>
						</div>
						{selectedRestaurant?.distance && (
							<div className="distance-wrapper">
								<IonIcon className="location-icon" icon={location} ></IonIcon>
								<SmallText className="block bold-description click-collect-small-description">
									{selectedRestaurant.distance < 1 ? selectedRestaurant.distance.toFixed(1) : selectedRestaurant.distance.toFixed(0)} {__(getDistanceUnit())}
								</SmallText>
							</div>
						)}
					</div>
				);
			} else {
				return (
					<div className="box-content order-header-content">
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								stroke="var(--okx-box-wrapper-color)"
								className="icon icon-tabler icon-tabler-map"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
								<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
								<line x1="9" y1="4" x2="9" y2="17"></line>
								<line x1="15" y1="7" x2="15" y2="20"></line>
							</svg>
	
							<Subtitle className="bold primary-color">{Basket.getRestauranName()}</Subtitle>
						</div>
						<div>
							<NormalText>{__('Collection time')}</NormalText>
							<NormalText >{Basket.getOrderTime()}</NormalText>
						</div>
					</div>
				);
			}
		}
	};
	onSearchHandler = () => {
		this.setState({ searchOpened: !this.state.searchOpened });
	};
	render() {
		const { __, scrollTop } = this.props;
		const { selectedCategory, ikentooMenu, selectedMenu } = this.state;
		let menuDisplayType = this.state.selectedMenuType;
		let categories = this.parseCategories({ selectedMenu, ikentooMenu, menuDisplayType });
		return (
			<Loading>
				<Layout
					searchActive={this.state.searchOpened}
					onSearchHandler={this.onSearchHandler}
					hasSearchIcon
					headerWithTitle
					title={__('Menu')}
					noPadding
					scrollY={false}
					backHandler={this.backHandler}
					showHamburger={selectedMenu ? selectedMenu.length === 0 : true}
					color="transparent"
				>
					{this.state.searchOpened && (
						<div className="search-box">
							<div>
								<IonIcon color="secondary" icon={search}></IonIcon>
								<IonInput
									placeholder={__('Search products')}
									value={this.state.searchItemsValue}
									onIonInput={(e) => {
										this.filterMenuItems(e.target.value);
									}}
								/>
								<IonIcon onClick={() => this.setState({ searchItemsValue: '', selectedMenu: [] })} className="reset-icon" color="secondary" icon={close}></IonIcon>
							</div>
						</div>
					)}
					{!isDefined(ikentooMenu) ? null : menuDisplayType === 1 ? (
						<div className="segment-holder">
							<div className="order-header-content-wrapper ">
								{ikentooMenu?.menuName?.indexOf('Default') === -1 && (
									<>
										<div className="default-padding">{this.drawContentHeader(__, Basket.getDeliveryOption(), Basket.getOrderType(), Basket.getDeliveryAddress())}</div>
									</>
								)}
							</div>
							{getConfig().frenchDisclaimer?.disclaimerImage && (
								<>
									<div className="order-disclaimer-wrapper">
										<img src={getConfig().frenchDisclaimer?.disclaimerImage}></img>
									</div>
								</>
							)}
							<div className="order-categories">
								{categories.length > 0 ? (
									<div className="order-categories-inner">
										{categories?.map((category, index) => {
											return (
												<IonButton
													key={index}
													size="small"
													fill="clear"
													className={selectedCategory === index ? 'category-button active' : 'category-button'}
													onClick={() => this.scrollTo(`${category.name}_${index}`, index)}
												>
													<StrongText>{__(category?.name)}</StrongText>
												</IonButton>
											);
										})}
									</div>
								) : (
									<>
										<Spacer />
										<NormalText className="no-items-message">{__('No items found')}</NormalText>
									</>
								)}
							</div>
							<div className="order-content">
								<OrderList
									scrollTopPosition={scrollTop}
									selectCategoryOnScroll={this.selectCategoryOnScroll}
									category={!isEmptyObject(ikentooMenu) ? ikentooMenu : null}
									menuRefs={this.state.menuRefs ? this.state.menuRefs : []}
								/>
								{Basket.itemsCount() > 0 ? (
									<div className="view-order-button">
										<IonButton onClick={() => forwardTo('/order-summary')} expand="block">
											<IonGrid>
												<IonRow>
													<IonCol>{__('View Order')}</IonCol>
													<IonCol>
														{Basket.itemsCountAll()}&nbsp;
														{Basket.itemsCountAll() === 1 ? __('Item') : __('Items')}
													</IonCol>
													<IonCol>{Basket._getTotal()}</IonCol>
												</IonRow>
											</IonGrid>
										</IonButton>
									</div>
								) : null}
							</div>
						</div>
					) : (
						<>
							<div className={`segment-holder grid-menus ${Basket.itemsCount() > 0 ? 'have-items-in-basket' : ''}`}>
								<div className="order-header-content-wrapper ">
									{ikentooMenu?.menuName?.indexOf('Default') === -1 && (
										<>
											<div className="default-padding">{this.drawContentHeader(__, Basket.getDeliveryOption(), Basket.getOrderType(), Basket.getDeliveryAddress())}</div>
											<Spacer size={1} />
										</>
									)}
								</div>
								{getConfig().frenchDisclaimer?.disclaimerImage && (
									<>
										<div className="order-disclaimer-wrapper">
											<img src={getConfig().frenchDisclaimer?.disclaimerImage}></img>
										</div>
									</>
								)}

								<div className="order-categories">
									<TileOrderList
										selectMenu={(index) => {
											this.selectMenu(index);
										}}
										category={categories}
										selectedMenu={this.state.selectedMenu}
									></TileOrderList>
								</div>
								<div className="order-content">
									{Basket.itemsCount() > 0 ? (
										<div className="view-order-button">
											<IonButton onClick={() => forwardTo('/order-summary')} expand="block" color="primary">
												<IonGrid>
													<IonRow>
														<IonCol>{__('View Order')}</IonCol>
														<IonCol>
															{Basket.itemsCountAll()}&nbsp;
															{Basket.itemsCountAll() === 1 ? __('Item') : __('Items')}
														</IonCol>
														<IonCol>{Basket._getTotal()}</IonCol>
													</IonRow>
												</IonGrid>
											</IonButton>
										</div>
									) : null}
								</div>
							</div>
						</>
					)}
				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	const { auth, clientProfile } = state.common;
	const { restaurants, ikentooMenu, defaultMenu, restaurantsUpdated } = state.restaurants;
	const { scrollTop } = state.orders;
	return {
		auth,
		restaurants: restaurants || [],
		ikentooMenu: ikentooMenu || {},
		defaultMenu: defaultMenu || {},
		basketUpdated: state.orders.basketUpdated,
		restaurantsUpdated,
		scrollTop,
		clientProfile,
	};
};

export default connect(stateToProps)(withTranslation(OrderPage));

/* eslint-disable no-mixed-spaces-and-tabs */
import { IonButton, IonCol, IonIcon, IonInput, IonItem, IonRow } from '@ionic/react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, SmallText, Spacer, Subtitle } from '../../components/common';
import Modal from '../../components/modal';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { deepIsDefined, getArrayDifference, goBack, validateForm } from '../../lib/utils';
import { checkDiscount, sendVoucherCode, showToast } from '../../store/actions';
import Layout from '../../components/layout';
import './index.css';
import VoucherCodeInput from '../../components/voucherCodeInput';
import { chevronForwardOutline } from 'ionicons/icons';

class ApplyVouchers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			discounts: null,
			selectedVoucher: null,
			showModal: null,
			voucherCode: '',
			disabledAppliedButton: false,
			formErrors: {
				voucherCode: '',
			},
			voucherCodeVisible: false,
		};
		this.formConfig = {
			voucherCode: { type: 'text', required: true },
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(checkDiscount(Basket.parseBasketForCheckVouchers()));
	}

	setVoucher = (voucher) => {
		this.setState({ selectedVoucher: voucher }, () => {
			const { vouchers, applicableVouchers } = this.props;
			const { selectedVoucher } = this.state;
			const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
			const applicableVoucher = applicableVouchers.find((appliableVoucher) => appliableVoucher.id === selectedVoucher.id);
			if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
				this.setState({ disabledAppliedButton: true });
				Basket.applyPoints(0);
			} else {
				this.setState({ disabledAppliedButton: false });
			}
		});
	};

	addVoucher = () => {
		const { selectedVoucher } = this.state;
		const { vouchers, applicableVouchers, dispatch, __ } = this.props;
		const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
		const applicableVoucher = applicableVouchers.find((appliableVoucher) => appliableVoucher.id === selectedVoucher.id);
		if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
			this.setState({ selectedVoucher: null }, () => {
				Basket.addVoucher(appliedVoucher, applicableVoucher);
				goBack();
			});
		} else {
			dispatch(showToast(__('Your basket is higher then voucher cost. Please add more items in basket'), 'warning'));
		}
	};

	redeemVoucher = (id) => {
		const { vouchers, applicableVouchers, dispatch, __ } = this.props;
		const appliedVoucher = vouchers.find((voucher) => voucher.id === id);
		const applicableVoucher = applicableVouchers.find((appliableVoucher) => appliableVoucher.id === id);
		if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
			Basket.addVoucher(appliedVoucher, applicableVoucher);
			goBack();
		} else {
			dispatch(showToast(__('Your basket is higher then voucher cost. Please add more items in basket'), 'warning'));
		}
	};

	showModal = (show) => {
		this.setState({ showModal: show });
		this.clearFields();
	};

	clearFields = () => {
		this.setState({ voucherCode: '' });
	};

	sendCode = () => {
		const { dispatch, __ } = this.props;
		let formErrors = validateForm(this.formConfig, this.state, __);
		this.setState({ formErrors });
		if (Object.keys(formErrors).length === 0) {
			const { voucherCode } = this.state;
			const data = { code: voucherCode.toUpperCase() };
			this.clearFields();
			dispatch(sendVoucherCode(data, true));
			this.showModal(false);
		}
	};

	filterVouchers = (vouchers, appliedVouchers) => {
		const { applicableVouchers, availableBalance } = this.props;
		let filteredVouchers = [];
		if (applicableVouchers && applicableVouchers.length > 0) {
			applicableVouchers.forEach((discount) => {
				let findedVoucher = vouchers.find((voucher) => voucher.id === discount.id);
				if (getConfig().vouchersType === 2) {
					if (findedVoucher && availableBalance > findedVoucher.cost) {
						filteredVouchers.push(findedVoucher);
					}
				} else {
					if (findedVoucher) {
						filteredVouchers.push(findedVoucher);
					}
				}
			});
		}
		if (appliedVouchers && appliedVouchers.length > 0) {
			const ids = appliedVouchers.map((i) => i.id);
			return filteredVouchers.filter((filteredVoucher) => ids.indexOf(filteredVoucher.id) === -1);
		}
		return filteredVouchers;
	};
	render() {
		const { __, vouchers } = this.props;
		const { selectedVoucher, voucherCode, showModal, disabledAppliedButton } = this.state;
		const appliedVouchers = Basket ? Basket.applied_vouchers : [];
		const filteredVouchers = this.filterVouchers(vouchers, appliedVouchers);
		const unavailableVouchers = vouchers?.length === filteredVouchers?.length ? false : getArrayDifference(vouchers, filteredVouchers);
		return (
			<Layout color="transparent" title={__('Apply a voucher')} backHandler={this.backHandler} scrollY={true} headerWithTitle={true}>
				<div className="flex-row-wrapper scrollable-y">
					<Subtitle className="bold">{__('Redeemable vouchers')}</Subtitle>
					<div className="vaucher-list" style={{ marginTop: '10px' }}>
						{filteredVouchers.length > 0 && (
							<div className="vauchers-buttons-wrapper">
								{filteredVouchers.map((filteredVoucher, index) => {
									return (
										<IonItem
											className="vaucher-button"
											onClick={() => this.redeemVoucher(filteredVoucher.id)}
										>
											<div>
												<Subtitle className='bold' >{filteredVoucher.reward.name}</Subtitle>
												<SmallText> {`${__('Expiry date')}: ${filteredVoucher.expiry_date}`}</SmallText>
											</div>
											<IonIcon icon={chevronForwardOutline}></IonIcon>
										</IonItem>
									);
								})}
							</div>
						)}
						<Spacer size={1} />
						<VoucherCodeInput buttonText='Unlock rewards' />
						{unavailableVouchers.length > 0 && (
							<>
								<Subtitle className="bold">{__('Other vouchers')}</Subtitle>
								<div className=" vaucher-list" style={{ marginTop: '10px' }}
								>
									<div className="vauchers-buttons-wrapper">
										{unavailableVouchers.map((filteredVoucher, index) => {
											return (
												<IonItem
													className="vaucher-button"
													disabled={true}
												>
													<div>
														<Subtitle className='bold' >{filteredVoucher.reward.name}</Subtitle>
														<SmallText> {`${__('Expiry date')}: ${filteredVoucher.expiry_date}`}</SmallText>
													</div>
													<IonIcon icon={chevronForwardOutline}></IonIcon>
												</IonItem>
											);
										})}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
				<Modal className="apply-voucher-modal" isOpen={!!showModal} onDidDismiss={() => this.setState({ showModal: null })} title={__('Enter code')}>
					<div className="flex-row-wrapper">
						<div className="centered flex-row-wrapper">
							<div className=" centered scrollable-y">
								<NormalText>{__('Enter a valid voucher code below to receive your reward')}</NormalText>
								<div className="lefted padded voucher-item">
									<IonItem style={{ '--min-height': '32px' }}>
										<IonInput value={voucherCode} onIonInput={(e) => this.setState({ voucherCode: e.target.value })} placeholder={__('Voucher code')} />
									</IonItem>
									<FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
								</div>
							</div>
						</div>
						<div className="flex-min">
							<IonButton onClick={() => this.sendCode()} expand="full" color="primary">
								{__('Submit')}
							</IonButton>
						</div>
					</div>
				</Modal>
			</Layout>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		availableBalance: deepIsDefined(store, 'profile.profile.available_balance') ? store.profile.profile.available_balance : 0,
		basketUpadated: store.orders.basketUpdated,
		vouchers: store.profile.vouchers || null,
		applicableVouchers: store.profile.applicableVouchers || null,
	};
};

export default connect(mapStateToProps)(withTranslation(ApplyVouchers));
